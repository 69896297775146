import React, { useState, useEffect } from 'react';
import './Countdown.css';

const Countdown = () => {
  const calculateTimeLeft = (endDate) => {
    const now = new Date().getTime();
    const difference = endDate - now;

    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState({});
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const storedEndDate = localStorage.getItem('countdownEndDate');

    if (storedEndDate) {
      setEndDate(new Date(storedEndDate).getTime());
    } else {
      const newEndDate = new Date();
      newEndDate.setDate(newEndDate.getDate() + 7);
      newEndDate.setHours(0, 0, 0, 0);
      localStorage.setItem('countdownEndDate', newEndDate);
      setEndDate(newEndDate.getTime());
    }
  }, []);

  useEffect(() => {
    if (endDate) {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft(endDate));
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [endDate]);

  if (Object.keys(timeLeft).length === 0 || (timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds === 0)) {
    return null;
  }

  return (
    <section className="countdown">
      <h1>Felix Tech Limited</h1>
      <h2>Our website will be live in:</h2>
      <div className="timer">
        <div className="time-unit">
          <span>{timeLeft.days || '0'}</span>
          <p>Days</p>
        </div>
        <div className="time-unit">
          <span>{timeLeft.hours || '0'}</span>
          <p>Hours</p>
        </div>
        <div className="time-unit">
          <span>{timeLeft.minutes || '0'}</span>
          <p>Minutes</p>
        </div>
        <div className="time-unit">
          <span>{timeLeft.seconds || '0'}</span>
          <p>Seconds</p>
        </div>
      </div>
    </section>
  );
};

export default Countdown;
