import './App.css';
import Countdown from './Countdown';
import React, { useState, useEffect } from 'react';

function App() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoaded(true), 300);
    return () => clearTimeout(timer);
  }, []);

  if (!isLoaded) return null;

  return (
    <div className="App">
      <Countdown />
      <footer className="footer">
        <p>Copyright © 2024 Felix Tech Limited. All Rights Reserved.</p>
      </footer>
    </div>
  );
}

export default App;
